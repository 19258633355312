import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';

const ResponsiveImage = ({ image, loading, sizes }) => {
  if (image.mobile) {
    var mobileImage = image.mobile.url.replace(/&h=[0,9]/, '')
    var mobilePadding = {
      paddingBottom: 100 / (get(image.mobile.dimensions, 'width') / get(image.mobile.dimensions, 'height')) + '%',
      aspectRatio: get(image.mobile.dimensions, 'width') + ' / ' + get(image.mobile.dimensions, 'height'),
    }
  }

  var desktopImage = image.url.replace(/&h=[0-9]*/, '').replace(/&w=[0-9]*/, '')
  var desktopPadding = {
    paddingBottom: 100 / (get(image.dimensions, 'width') / get(image.dimensions, 'height')) + '%',
    aspectRatio: get(image.dimensions, 'width') + ' / ' + get(image.dimensions, 'height'),
  }

  var imageLoading = loading || 'lazy'
  var imageSizes = sizes || '(min-width: 640px) 640w, (min-width: 768px) 768w, (min-width: 1024px) 1024w, (min-width: 1280px) 1280w, 100vw'

  return (
    <div className='responsive-image relative overflow-hidden lg:object-fill h-full w-full'>
      {mobilePadding ? (
        <>
          <div style={desktopPadding} className='hidden md:block lg:block xl:block w-full h-full'/>
          <div style={mobilePadding} className='block md:hidden lg:hidden xl:hidden w-full bg-cover'/>
        </>
      ):(
        <div style={desktopPadding} className='w-full bg-cover'/>
      )}
      <picture>
        <source
          media='(min-width: 768px)'
          srcSet={`${desktopImage}&w=320 320w,
                   ${desktopImage}&w=640 640w,
                   ${desktopImage}&w=768 768w,
                   ${desktopImage}&w=1024 1024w,
                   ${desktopImage}&w=1280 1280w,
                   ${desktopImage}&w=1800 1800w,
                   ${desktopImage}&w=2340 2340w`}
          sizes={imageSizes}
        />
        <img
          alt={get(image, 'alt') || ''}
          className='w-full h-full top-0 left-0 object-cover absolute object-center'
          loading={imageLoading}
          sizes={imageSizes}
          src={`${get(image, 'url')}&amp;w=1200`}
          srcSet={`${mobileImage || desktopImage}&w=320 320w,
                   ${mobileImage || desktopImage}&w=640 640w,
                   ${mobileImage || desktopImage}&w=768 768w,
                   ${mobileImage || desktopImage}&w=1024 1024w,
                   ${mobileImage || desktopImage}&w=1280 1280w,
                   ${mobileImage || desktopImage}&w=1800 1800w,
                   ${mobileImage || desktopImage}&w=2340 2340w`}
        />
      </picture>
    </div>
  )
}

ResponsiveImage.propTypes = {
  image: PropTypes.object.isRequired,
  loading: PropTypes.string,
  sizes: PropTypes.string,
}

export default ResponsiveImage
