import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import Card from '../components/card'


const CardDeck = ({ slice }) => {
  return (
    <React.Fragment>
      <>
        {get(slice.primary, 'deckTitle') && (
          <div className='deck-title mb-3 sm:mb-5 px-4 sm:px-0 font-serif text-green-800 font-normal tracking-wide'>
            <h2 className='font-normal '>{RichText.asText(get(slice.primary, 'deckTitle'), linkResolver)}</h2>
          </div>
        )}
        <ul className={`component-${slice.type} grid grid-cols-4 gap-2 md:gap-6`}>
          {slice.fields.map((card, index) => {
            return (
              <React.Fragment key={`card-${index}`}>
                <Card fields={card} />
              </React.Fragment>
            )
          })}
        </ul>
      </>
    </React.Fragment>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default CardDeck
