import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Link } from 'gatsby'
import ResponsiveImage from '../components/responsiveImage'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'


const BannerWithCaption = ({ slice }) => {
  var link = get(slice.primary.button_link, 'url',(linkResolver(get(slice.primary.button_link, '_meta',[]),[])))

  return (
    <React.Fragment>
      <div className={`component-${slice.type} relative overflow-hidden bg-green-800 text-yellow-100 rounded-lg shadow-xl`}>
        <div className='banner lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2'>
          <ResponsiveImage image={slice.primary.image} sizes='(min-width: 640px) 640w, (min-width: 768px) 768w, (min-width: 1024px) 512w, (min-width: 1280px) 640w, 100vw' />
        </div>
        <div className='wrapper lg:float-right pb-8 sm:pb-12 md:pb-20 lg:max-w-2xl lg:w-1/2 lg:pb-24 xl:pb-32'>
          <div className='caption mt-10 mx-auto max-w-screen-xl px-6 sm:text-center md:mt-16 lg:mt-20 lg:text-left xl:mt-24'>
            {get(slice.primary, 'title') && (
              <div className='text-yellow-200'>
                {RichText.render(get(slice.primary, 'title'), linkResolver)}
              </div>
            )}
            <div className='text-lg mt-3 sm:mt-5 sm:max-w-xl sm:mx-auto lg:mx-0'>{RichText.render(get(slice.primary, 'text'), linkResolver)}</div>
            {link !== '/undefined' && (
              <div className='mt-5 md:mt-8 md:flex md:justify-center lg:justify-start'>
                {get(slice.primary.button_link, 'url') ? (
                  <a className='button rounded-full text-yellow-200 border-yellow-200 border-2 bg-green-800 hover:bg-yellow-200 hover:text-green-800' href={link}>{get(slice.primary, 'button_text')}</a>
                ):(
                  <Link className='button rounded-full text-yellow-200 border-yellow-200 border-2 bg-green-800 hover:bg-yellow-200 hover:text-green-800' to={link}>{get(slice.primary, 'button_text')}</Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
