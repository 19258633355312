import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import ResponsiveImage from '../components/responsiveImage'


const List = ({ slice }) => {
  return (
    <React.Fragment>
      <>
        {get(slice.primary, 'listTitle') && (
          <h1>
            {RichText.asText(get(slice.primary, 'listTitle'), linkResolver)}
          </h1>
        )}
        <ul className={`component-${slice.type} sm:rounded-lg shadow print:shadow-none bg-white print:text-xs`}>
          {slice.fields.map((item, index) => {
            return (
              <li key={`card-${index}`} className='overflow-hidden sm:px-6 print:px-0'>
                <div className='border-b print:py-1 py-5 sm:grid sm:grid-cols-6 sm:gap-4'>
                  <div className='col-span-6 md:col-span-3 lg:col-span-2 print:hidden'>
                    {get(item, 'list_item_image') ? (
                      <ResponsiveImage image={get(item, 'list_item_image')} sizes='(min-width: 640px) calc(100vw - 4rem), (min-width: 768px) calc(50vw - 2.5rem), (min-width: 1024px) 33vw, (min-width: 1280px) 395w, calc(100vw - 1rem)' />
                    ):(
                      <div className='bg-gray-100 h-full text-center flex flex-col justify-center text-gray-700 text-sm'>No Image Available</div>
                    )}
                  </div>
                  <div className='col-span-6 md:col-span-3 lg:col-span-4 px-3 md:px-0 flex flex-col items-start print:col-span-6'>
                    <div className='font-bold text-green-100 bg-green-600 px-2 print:px-0 py-1'>LOT: {item.lot}</div>
                    <div className='my-4 print:my-1'>{RichText.render(get(item, 'list_item_text'), linkResolver)}</div>
                    <div className='mt-auto font-bold print:text-base text-xl leading-tight'>{item.price}</div>
                    {item.price_achieved && (<div className='font-bold print:text-base text-xl leading-tight text-green-600'>{item.price_achieved}</div>)}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </>
    </React.Fragment>
  )
}

List.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default List
