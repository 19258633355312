import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';


const Hero = ({ slice }) => {
  var link = get(slice.primary.button_link, 'url',(linkResolver(get(slice.primary.button_link, '_meta',[]),[])))

  return (
    <React.Fragment>
      <div className={`component-${slice.type} relative print:py-0 pt-16 pb-32 flex content-center items-center justify-center mb-8`}>
        <div className='print:hidden absolute top-0 w-full h-full bg-center bg-cover' style={{backgroundImage: 'url(' + get(slice.primary.image, 'url')}}>
          <span id="blackOverlay" className="w-full h-full absolute opacity-25 bg-black"></span>
        </div>
        <div className='container relative mx-auto'>
          <div className='items-center flex flex-wrap'>
            <div className='w-full lg:w-3/4 px-4 ml-auto mr-auto text-center'>
              <div className='title text-white print:text-black tracking-wide font-serif text-5xl font-normal'>{RichText.asText(get(slice.primary, 'title'), linkResolver)}</div>
              {get(slice.primary, 'text') && <div className='my-6 text-white print:text-black font-semibold print:text-base text-2xl'>{RichText.render(get(slice.primary, 'text'), linkResolver)}</div>}
              {link !== '/undefined' && (
                <>
                  {get(slice.primary.button_link, 'url') ? (
                    <a className='print:hidden button bg-white text-black border-black border-2' href={link}>{get(slice.primary, 'button_text')}</a>
                  ):(
                    <Link className='print:hidden button bg-white text-black border-black border-2' to={link}>{get(slice.primary, 'button_text')}</Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Hero
