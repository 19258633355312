import React, { useState, useEffect, useCallback } from 'react';
import { useEmblaCarousel } from 'embla-carousel-react';
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';
import ResponsiveImage from '../components/responsiveImage'

const Carousel = ({ slice }) => {

  const [EmblaCarouselReact, embla] = useEmblaCarousel({ loop: false });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback(index => embla.scrollTo(index), [embla]);
  const scrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla.scrollNext(), [embla]);

  useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(embla.selectedScrollSnap());
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    };
    if (embla) {
      embla.on('select', onSelect);
      onSelect();
    }
  }, [embla]);

  const PrevButton = ({ enabled, onClick }) => (
    <button
      className='absolute left-0 w-10 h-10 ml-5 -mt-16 rounded-full hover:bg-green-900 hover:opacity-75 flex justify-center'
      onClick={onClick}
      disabled={!enabled}
      style={{top:'50%'}}
    >
      <div className='sr-only'>Previous</div>
      <svg className='h-6 mt-2 text-white' fill='currentColor' viewBox='137.718 -1.001 366.563 644'>
        <path d='M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z' />
      </svg>
    </button>
  );

  const NextButton = ({ enabled, onClick }) => (
    <button
      className='absolute right-0 w-10 h-10 mr-5 -mt-16 rounded-full hover:bg-green-900 hover:opacity-75 flex justify-center'
      onClick={onClick}
      disabled={!enabled}
      style={{top:'50%'}}
    >
      <div className='sr-only'>Next</div>
      <svg className='h-6 mt-2 text-white' fill='currentColor' viewBox='0 0 238.003 238.003'>
        <path d='M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z' />
      </svg>
    </button>
  );

  return (
    <React.Fragment>
      {get(slice.primary, 'gallery_name') && RichText.asText(get(slice.primary, 'gallery_name')) !== '' && (
        <div className='fdeck-title mb-3 sm:mb-5 px-4 sm:px-0 font-serif text-green-800 font-normal tracking-wide'><h2 className='font-normal'>{RichText.asText(slice.primary.gallery_name, linkResolver)}</h2></div>
      )}
      <div className='relative'>
        <EmblaCarouselReact>
          <div style={{ display: 'flex' }}>
            {slice.fields.map((item, index) => {
              return (
                <div key={`item-${index}`} style={{ flex: '0 0 100%' }}>
                  {item.image && (
                    <ResponsiveImage image={item.image} sizes='(min-width: 1024px) 512w, (min-width: 1280px) 640w, 100vw' />
                  )}
                </div>
              )
            })}
          </div>
          <div className='my-2 grid grid-cols-4 md:grid-cols-8 gap-2'>
            {slice.fields.map((item, index) => (
              <button
                className={`rounded-md overflow-hidden w-full bg-white border border-white hover:border-green-400${index === selectedIndex ? ' border-green-300' : ''}`}
                onClick={() => scrollTo(index)}
                key={index}
              >
                {item.image && (
                  <>
                    <ResponsiveImage image={item.image} sizes='(min-width: 640px) 152w, (min-width: 768px) 87w, (min-width: 1024px) 119w, (min-width: 1280px) 151w, calc(25vw - 0.375rem)' />
                    <span className='sr-only'>
                      {item.image.alt ? (
                        <>{item.image.alt}</>
                      ):(
                        <>Picture {index}</>
                      )}
                    </span>
                  </>
                )}
              </button>
            ))}
          </div>
        </EmblaCarouselReact>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>
    </React.Fragment>
  )
}

Carousel.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Carousel
