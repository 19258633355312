import React from 'react'
import PropTypes from 'prop-types'

const Map = ({ slice }) => {

  return (
    <div className='relative' style={{paddingBottom:'56.25%'}}>
      <iframe className='absolute inset-0' width='100%' height='100%' title='Google Map of Auction Venue' src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD9wReXDmZXESKfYf41UWyUaDRcYDumkFc&q=${slice.primary.google_maps.latitude},${slice.primary.google_maps.longitude}`} allowFullScreen />
    </div>
  )
}

Map.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Map
