import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Link } from 'gatsby'
import ResponsiveImage from '../components/responsiveImage'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'


const Card = ({ fields }) => {
  var link = get(fields.button_link, 'url',(linkResolver(get(fields.button_link, '_meta',[]),[])))
  var grid = 'col-span-2 lg:col-span-1'

  let cardContents = (
    <React.Fragment>
      {get(fields.image, 'url') && (
        <div className='image overflow-hidden transform duration-500 ease-in hover:-translate-y-1 hover:scale-105 rounded-t-lg'>
          <ResponsiveImage image={get(fields, 'image')} sizes='(min-width: 640px) 316w, (min-width: 768px) 372w, (min-width: 1024px) 238w, (min-width: 1280px) 302w, calc(50vw - .5rem)' />
        </div>
      )}
      <div className='flex flex-col flex-grow bottom-0 h-full'>
        <div className='pt-0 bg-green-800 hover:text-yellow-200 text-yellow-100 p-4 h-full'>
          {get(fields, 'title') && (<div>{RichText.render(get(fields, 'title'), linkResolver)}</div>)}
          {get(fields, 'text') && (
            <div className='leading-tight'>
              {RichText.render(get(fields, 'text'), linkResolver)}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <li className={`component-card overflow-hidden p-0 relative transition rounded-lg shadow-lg ${grid}`}>
      {link && link !== '/undefined' ? (
        <>
         {get(fields.button_link, 'url') ? (
           <a className='block h-full' href={link}>{cardContents}</a>
          ):(
            <Link className='block h-full' to={link}>{cardContents}</Link>
          )}
        </>
      ):(
        <>
        {cardContents}
        </>
      )}
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired,
}

export default Card
