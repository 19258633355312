import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'


const Text = ({ slice }) => {
  return (
    <React.Fragment>
      <div className={`component-${slice.type}`}>{RichText.render(get(slice.primary, 'text'), linkResolver)}</div>
    </React.Fragment>
  )
}

Text.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Text
